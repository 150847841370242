// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktualnosci-js": () => import("./../src/pages/aktualnosci.js" /* webpackChunkName: "component---src-pages-aktualnosci-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inwestycje-js": () => import("./../src/pages/inwestycje.js" /* webpackChunkName: "component---src-pages-inwestycje-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-lakierowanie-js": () => import("./../src/pages/lakierowanie.js" /* webpackChunkName: "component---src-pages-lakierowanie-js" */),
  "component---src-pages-nadruk-js": () => import("./../src/pages/nadruk.js" /* webpackChunkName: "component---src-pages-nadruk-js" */),
  "component---src-pages-obrobki-js": () => import("./../src/pages/obrobki.js" /* webpackChunkName: "component---src-pages-obrobki-js" */),
  "component---src-pages-spedycja-js": () => import("./../src/pages/spedycja.js" /* webpackChunkName: "component---src-pages-spedycja-js" */),
  "component---src-pages-uslugi-js": () => import("./../src/pages/uslugi.js" /* webpackChunkName: "component---src-pages-uslugi-js" */)
}

