import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"

const HamburgerInner = styled.span`
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: background-color 0.1s 0.2s ease-in-out;
  width: 100%;
  height: 3px;
  background-color: ${({ hamburger }) =>
    hamburger ? "transparent" : "#da1e35"};
  position: absolute;

  &::before,
  &::after {
    content: "";
    left: 0;
    transition: transform 0.2s 0.2s ease-in-out;
    width: 100%;
    height: 3px;
    background-color: #da1e35;
    position: absolute;
  }

  &::before {
    top: -10px;
    transform: ${({ hamburger }) =>
      hamburger
        ? "translateY(12px) rotate(135deg)"
        : "translateY(0) rotate(0)"};
  }

  &::after {
    top: 10px;
    transform: ${({ hamburger }) =>
      hamburger
        ? "translateY(-8px) rotate(-135deg)"
        : "translateY(0) rotate(0)"};
  }
`

const HamburgerButton = styled.button`
  padding: 10px;
  display: inline-block;
  cursor: pointer;
  background-color: #ffffff;
  border: 0;
  margin: 0;
  width: fit-content;
  transition: transform 0.3s 0.1s ease-in-out;
  transform: ${({ hamburger }) =>
    hamburger ? "translateX(250px)" : "translateX(0)"};
  z-index: 100;
  position: absolute;

  @media screen and (min-width: 1280px) {
    display: none;
  }
`

const HamburgerBox = styled.span`
  width: 35px;
  height: 24px;
  display: inline-block;
  position: relative;
`

const NavigationWrapper = styled.nav`
  height: 100vh;
  width: 250px;
  background-color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  transform: ${({ showNav }) =>
    showNav ? "translateX(0px)" : "translateX(-1280px)"};
  transition: transform 0.3s 0.1s ease-in-out;
  z-index: 100;

  @media screen and (min-width: 1280px) {
    height: 50px;
    width: 100vw;
    transform: translateX(0);
    top: 65px;
    background: transparent;
  }
`

const NavigationList = styled.ul`
  margin-top: 50px;
  list-style: none;
  height: 100%;

  @media screen and (min-width: 1280px) {
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const NavigationListItem = styled.li`
  font-size: 18px;
  margin: 25px 10px;

  a {
    text-decoration: none;
    color: #000000;
    font-weight: 500;
    transition: color 0.2s;

    &:hover {
      color: #da1e35;
    }
  }
`

const StyledUeLogos = styled(Image)`
  width: 100%;
  margin: 0 auto;
  max-width: 660px;
`

const StyledLogo = styled(Image)`
  width: 100px;
  margin: 0 auto;

  @media screen and (min-width: 1280px) {
    margin: 0 20px;
    z-index: 100;
  }
`
const StyledLink = styled(Link)`
  margin: 0 auto;
  cursor: pointer;
  @media screen and (min-width: 1280px) {
    margin: 0 20px;
    z-index: 9;
  }
`

const StyledHamburgerLogoContainer = styled.div`
  display: flex;
`

const query = graphql`
  {
    logo: file(name: { eq: "logo" }) {
      childImageSharp {
        fluid(maxWidth: 131, maxHeight: 65) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    ue_logos: file(name: { eq: "ue_logos2" }) {
      childImageSharp {
        fluid(maxWidth: 746, maxHeight: 81) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const Navigation = () => {
  const [menu, showMenu] = useState(false)

  const data = useStaticQuery(query)

  return (
    <>
      <StyledUeLogos fluid={data.ue_logos.childImageSharp.fluid} />
      <StyledHamburgerLogoContainer>
        <HamburgerButton
          hamburger={menu}
          className={"hamburger"}
          onClick={() => showMenu(!menu)}
        >
          <HamburgerBox>
            <HamburgerInner hamburger={menu} />
          </HamburgerBox>
        </HamburgerButton>
        <StyledLink to="/">
          <StyledLogo fluid={data.logo.childImageSharp.fluid} />
        </StyledLink>
      </StyledHamburgerLogoContainer>
      <NavigationWrapper className={"navigation"} showNav={menu}>
        <NavigationList>
          <NavigationListItem>
            <Link to="/" onClick={() => showMenu(!menu)}>
              Strona główna
            </Link>
          </NavigationListItem>
          <NavigationListItem>
            <Link to="/uslugi" onClick={() => showMenu(!menu)}>
              Usługi
            </Link>
          </NavigationListItem>
          <NavigationListItem>
            <Link to="/inwestycje" onClick={() => showMenu(!menu)}>
              Inwestycje
            </Link>
          </NavigationListItem>
          <NavigationListItem>
            <Link to="/aktualnosci" onClick={() => showMenu(!menu)}>
              Aktualności
            </Link>
          </NavigationListItem>
          <NavigationListItem>
            <Link to="/kontakt" onClick={() => showMenu(!menu)}>
              Kontakt
            </Link>
          </NavigationListItem>
        </NavigationList>
      </NavigationWrapper>
    </>
  )
}

export default Navigation
