import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
  {
    footer_red_background: file(name: { eq: "footer_red_background" }) {
      publicURL
      childImageSharp {
        fluid(maxHeight: 176) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    facebook: file(name: { eq: "facebook" }) {
      publicURL
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    linkedin: file(name: { eq: "linkedin" }) {
      publicURL
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    phone: file(name: { eq: "phone" }) {
      publicURL
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    email: file(name: { eq: "email" }) {
      publicURL
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    place: file(name: { eq: "place" }) {
      publicURL
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  margin: 0 auto;

  @media screen and (min-width: 1280px) {
    height: 260px;
  }
`

const FooterWrapper = styled("div")`
  background-color: #da1e35;
  width: 100%;
  background-size: cover;

  @media screen and (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  @media screen and (min-width: 1280px) {
    background-image: url(${({ image }) => image});
    background-size: contain;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position-y: bottom;
    height: 176px;
    min-height: 176px;
    align-items: flex-end;
  }
`

const FooterColumn = styled("div")`
  display: flex;
  align-items: center;
  margin: 0 40px;
  @media screen and (min-width: 768px) {
    margin: 0 10px;
  }
  @media screen and (min-width: 1280px) {
    margin: 0 10px;
    padding-bottom: 15px;
  }
  p {
    color: #fff;
    font-size: 14px;
    margin: 5px 0;
  }
`

const FooterColumnIcons = styled("div")`
  display: flex;
  align-items: center;
  margin: 0 40px;

  @media screen and (min-width: 768px) {
    margin: 0 10px;
    align-self: flex-start;
  }

  @media screen and (min-width: 1280px) {
    margin: 0 10px;
    align-self: flex-end;
    padding-bottom: 15px;
  }
`

const StyledBtn = styled("a")`
  background-image: url(${({ image }) => image});
  background-size: contain;
  background-color: transparent;
  outline: none;
  border: none;
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin: 15px 10px;
`
const StyledColumnTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledCreated = styled.p`
  text-align: center;
  font-weight: 600;
  margin-top: 20px;
  font-size: 14px;
`

const Footer = () => {
  const data = useStaticQuery(query)

  return (
    <>
      <FooterContainer>
        <FooterWrapper
          image={data.footer_red_background.childImageSharp.fluid.src}
        >
          <FooterColumn>
            <StyledBtn
              image={data.phone.childImageSharp.fluid.src}
              href={"https://google.pl"}
              target={"_blank"}
            />
            <StyledColumnTextContainer>
              <p>+43 313 09 69</p>
              <p>+ komórkowy?</p>
            </StyledColumnTextContainer>
          </FooterColumn>
          <FooterColumn>
            <StyledBtn
              image={data.email.childImageSharp.fluid.src}
              href={"https://google.pl"}
              target={"_blank"}
            />
            <StyledColumnTextContainer>
              <p>kontakt@grupamarcus.pl</p>
              <p>www.grupa-marcus.pl</p>
            </StyledColumnTextContainer>
          </FooterColumn>
          <FooterColumn>
            <StyledBtn
              image={data.place.childImageSharp.fluid.src}
              href={"https://google.pl"}
              target={"_blank"}
            />
            <StyledColumnTextContainer>
              <p>Przemysłowa 2</p>
              <p>42-300 Myszków</p>
            </StyledColumnTextContainer>
          </FooterColumn>
          <FooterColumnIcons>
            <StyledBtn
              image={data.facebook.childImageSharp.fluid.src}
              href={"https://google.pl"}
              target={"_blank"}
            />
            <StyledBtn
              image={data.linkedin.childImageSharp.fluid.src}
              href={"https://google.pl"}
              target={"_blank"}
            />
          </FooterColumnIcons>
        </FooterWrapper>
        <StyledCreated>
          &#169; Copyright 2020 PPHU MARCUS All Rights Reserved
        </StyledCreated>
      </FooterContainer>
    </>
  )
}

export default Footer
