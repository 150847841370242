import React from "react"
import Navigation from "../components/Navigation/Navigation"
import GlobalStyle from "../assets/styles/globalStyles"
import Footer from "../components/Footer/Footer"
import styled from "styled-components"

const ContentWrapper = styled.div`
  max-width: 1280px;
  width: 100vw;
  min-height: 75vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`

const MainLayout = ({ children }) => (
  <>
    <ContentWrapper>
      <GlobalStyle />
      <Navigation />
      {children}
    </ContentWrapper>
    <Footer/>
  </>
)

export default MainLayout
