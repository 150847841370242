import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`

 html {
    box-sizing: border-box;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat';
    width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;
  }
  button {
    padding: 0;
    cursor: pointer;
    font-family: 'Montserrat';
  }
  p {
    font-size: 16px;
  }
  ul {
    padding: 0;
    margin: 0;
  }
`

export default GlobalStyle
